import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Form,
  Select, Upload,
  message,
  DatePicker,
  Space
} from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DashbordSidebar from "../../../components/DashboardSidebar";
import "react-phone-number-input/style.css";
import swal from "sweetalert";
import { PROFILE, UPLOADS_URL, USER_AUTH } from "../../../config/constants/api";
import { Put } from "../../../config/api/put";
import moment from "moment";
import { addProfileDetails, addUser } from "../../../redux/slice/authSlice";
import { FaCamera } from "react-icons/fa";
import { Country, State, City } from "country-state-city";
import dayjs from "dayjs";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    message.error("Invalid Uplaod, You can only upload image files!");
  }
  return isImage;
};
const DropzoneFiltercards = () => {
  const [loading, setLoading] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [form] = Form.useForm();
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const userData = useSelector((state) => state.user.userData);
  const [countries, setCountries] = useState([]);
  const [cities, setCitites] = useState([]);
  const [states, setStates] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [countryIso, setCountryIso] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("");
  const [stateIso, setStateIso] = useState("");
  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  useEffect(() => {
    setStateName("");
    setStates(State.getStatesOfCountry(countryIso));
  }, []);
  useEffect(() => {
    setCityName("");
    setCitites(City.getCitiesOfState(countryIso, stateIso));
  }, []);
  useEffect(() => {
    const countryIsoCode =
      userData.country &&
      Country.getAllCountries().find(
        (country) => country.name === userData.country
      )?.isoCode;
    setCountryIso(countryIsoCode);
    setCountryName(userData.country);

    // Populate states based on the country
    if (countryIsoCode) {
      const states = State.getStatesOfCountry(countryIsoCode);
      setStates(states);

      const stateIsoCode =
        userData.state &&
        states.find((state) => state.name === userData.state)?.isoCode;
      setStateIso(stateIsoCode);
      setStateName(userData.state);

      // Populate cities based on the state
      if (stateIsoCode) {
        const cities = City.getCitiesOfState(countryIsoCode, stateIsoCode);
        setCitites(cities);

        const cityName =
          userData.city &&
          cities.find((city) => city.name === userData.city)?.name;
        setCityName(cityName);
      }
    }
    form.setFieldsValue({
      firstName: userData?.firstName, 
      lastName : userData?.lastName,
      email: userData?.email,
      phone: userData?.mobile,
      gender: userData?.gender,
      dateOfBirth: dayjs(userData?.dateOfBirth),
    });
  }, []);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!profileDetails) {
  //     navigate("/createProfile");
  //   }
  // }, []);
  const navigate = useNavigate();
  const onFinish = (values) => {
    const {
      country,
      city,
      state,
      firstName,
      lastName,
      gender,
      phone,
      dateOfBirth,
    } = values;
    const formValuesChanged = () => {
      return (
        userData?.firstName !== firstName ||
        userData?.lastName !== lastName ||
        userData?.country !== country ||
        userData?.city !== city ||
        userData?.state !== state ||
        userData?.gender !== gender ||
        !dayjs(userData?.dateOfBirth).isSame(dateOfBirth) ||
        userData?.mobile !== phone ||
        imageObject 
      );
    };
    if (formValuesChanged()) {
      let data = new FormData();
      if (imageObject) {
        data.append("image", imageObject);
      }
      data.append("dateOfBirth", moment(dateOfBirth?.$d).format("YYYY-MM-DD"));
      data.append("mobile", phone);
      data.append("gender", gender);
      data.append("country",  countryName);
      data.append("city", cityName);
      data.append("state", stateName);
      data.append("firstName", firstName);
      data.append("lastName", lastName);
      Put(USER_AUTH.updateAccountDetails, token, data, {}, "multipart")
        .then((response) => {
          if (response?.status) {
            form.resetFields();
            swal("Success", "Profile Updated Successfully", "success");
            dispatch(addUser({ user: response?.data, token: token }));
            navigate("/Profile");
          }
        })
        .catch((err) => {
          console.log("Error", err);
          swal("Error", err?.response?.data?.message, "error");
        });
    } else {
      swal("System Alert", "No Changes Detected", "error");
      return;
    }
  };
  const handleChangepro = (info) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
        setImageObject(
          info?.fileList[info?.fileList?.length - 1]?.originFileObj
        );
        setLoading(false);
        setImageUrl(url);
      }
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (fieldName, value, e) => {
    switch (fieldName) {
      case "City":
        setCityName(value);
        break;
      case "Country":
        const { name, isoCode } = JSON.parse(value);
        setCountryName(name);
        setCountryIso(isoCode);
        break;
      case "State":
        setStateName(value);
        setStateIso(e?.key);
        break;
      default:
        return;
    }
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="profile-information-wrapper">
                        <h3 className="main-heading">Edit Profile</h3>
                      </div>
                      <div className="bg-parent">
                        <Row
                          gutter={[16, 16]}
                          align={""}
                          justify={"space-between"}
                        >
                          <Col md={10} lg={10} xl={8}>
                            <div className="wrapper-group-1000001858">
                              <>
                                <Upload
                                  name="image"
                                  showUploadList={false}
                                  style={{ position: "relative" }}
                                  onChange={handleChangepro}
                                  beforeUpload={beforeUpload}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      height: "300px",
                                      border: "1px solid gray",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {imageUrl ? (
                                      <img
                                        src={imageUrl}
                                        alt="avatar"
                                        style={{
                                          width: "100%",
                                          maxHeight: "300px",
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={
                                          UPLOADS_URL + userData?.image
                                        }
                                        alt="avatar"
                                        style={{
                                          width: "100%",
                                          maxHeight: "300px",
                                          objectFit: "cover",
                                          objectPosition: "center",
                                          filter: "blur(1px)",
                                        }}
                                      />
                                    )}
                                    <FaCamera
                                      style={{
                                        position: "absolute",
                                        color: "rgb(0,127,202)",
                                        fontSize: "25px",
                                      }}
                                    />
                                  </div>{" "}
                                </Upload>

                                {/* <div>
                                  <div>
                                    <input
                                      id="media"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={handleImageChange}
                                    />
                                  </div>
                                  <div style={{ marginTop: "1rem" }}>
                                    {imageObject ? (
                                      <div>
                                        <img
                                          src={URL.createObjectURL(imageObject)}
                                          alt="Preview"
                                          style={{
                                            maxWidth: "100%",
                                            maxHeight: "200px",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                          }}
                                        />
                                        <button onClick={handleRemoveImage}>
                                          Remove Image
                                        </button>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          textAlign: "center",
                                          // border: "2px solid black",
                                          height: "350px",
                                          cursor: "pointer",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        onClick={() =>
                                          document
                                            .getElementById("media")
                                            .click()
                                        }
                                      >
                                        <img
                                          src={
                                            UPLOADS_URL + profileDetails?.image
                                          }
                                          alt="avatar"
                                          style={{
                                            maxHeight: "360px",
                                            filter: "blur(1px)",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                          }}
                                        />
                                        <FaCamera
                                          style={{
                                            position: "absolute",
                                            color: "rgb(0,127,202)",
                                            fontSize: "25px",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>  */}
                              </>
                            </div>
                          </Col>

                          <Col md={14} lg={14} xl={16}>
                            <div className="">
                              <div className="logo-rectangle">
                                <div className="edit-profile-info">
                                  <Form
                                    className="row g-3"
                                    name="basic"
                                    layout="vertical"
                                    initialValues={{
                                      remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                  >
                                    <Row
                                      style={{ width: "100%" }}
                                      gutter={[16, 16]}
                                    >
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="First Name"
                                          name="firstName"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Last Name"
                                          name="lastName"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item label="Email" name="Email">
                                          <Input
                                            size="large"
                                            className="web-input"
                                            disabled
                                            placeholder={userData?.email}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Phone Number"
                                          name="phone"
                                        >
                                          <Input
                                            size="large"
                                            className="web-input"
                                            type="number"
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item
                                          label="Date Of Birth"
                                          name="dateOfBirth"
                                        >
                                          <DatePicker className="web-input" />
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                        <Form.Item label="Gender" name="gender">
                                          <Select placeholder="Select">
                                            <Select.Option value="MALE">
                                              Male
                                            </Select.Option>
                                            <Select.Option value="FEMALE">
                                              Female
                                            </Select.Option>
                                            <Select.Option value="OTHERS">
                                              Others
                                            </Select.Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                      <Form.Item
                                          label="Country"
                                          name="country"
                                        >
                                          <Space wrap className="filter-select">
                                            <Select
                                              size="large"
                                              // defaultValue="Select"
                                              value={countryName || "Select"}
                                              showSearch
                                              placeholder="Country"
                                              optionFilterProp="children"
                                              onChange={(val, e) => {
                                                handleChange("Country", val, e);
                                              }}
                                              filterOption={(input, option) =>
                                                option.value
                                                  .toLowerCase()
                                                  .includes(input.toLowerCase())
                                              }
                                              options={countries?.map(
                                                (country) => ({
                                                  value: JSON.stringify({
                                                    name: country.name,
                                                    isoCode: country.isoCode,
                                                  }),
                                                  label: country?.name,
                                                })
                                              )}
                                            />
                                          </Space>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                      <Form.Item label="city">
                                          <Space wrap className="filter-select">
                                            <Select
                                              // defaultValue="Select"
                                              onChange={(val, e) => {
                                                handleChange("City", val, e);
                                              }}
                                              value={cityName}
                                              style={{ width: "100%" }}
                                            >
                                              {cities.map((cityObj) => (
                                                <Select.Option
                                                  key={cityObj?.isoCode}
                                                  value={cityObj?.name}
                                                >
                                                  {cityObj?.name}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          </Space>
                                        </Form.Item>
                                      </Col>
                                      <Col lg={12} md={12} xs={24}>
                                      <Form.Item label="state">
                                          <Space wrap className="filter-select">
                                            <Select
                                              // defaultValue="Select"
                                              onChange={(val, e) => {
                                                handleChange("State", val, e);
                                              }}
                                              value={stateName}
                                              style={{ width: "100%" }}
                                            >
                                              {states.map((stateObj) => (
                                                <Select.Option
                                                  key={stateObj?.isoCode}
                                                  value={stateObj?.name}
                                                >
                                                  {stateObj?.name}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          </Space>
                                        </Form.Item>
                                      </Col>
                                   
                                      {/* <Col lg={24} md={12} xs={24}>
                                        <Form.Item
                                          label="Date Of Birth"
                                          name="DOB"
                                        >
                                          <DatePicker className="web-input" />
                                        </Form.Item>
                                      </Col> */}
                                    
                                    </Row>
                                    <div
                                        className=""
                                        style={{ textAlign: "center" }}
                                      >
                                        <Button
                                          type=""
                                          htmlType="submit"
                                          className="btn web-btn px-5"
                                        >
                                          Update
                                        </Button>
                                      </div>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
